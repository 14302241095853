"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    busyDelay: 500,
    genericError: 'Something went wrong, please try again',
    apiBase: 'https://shopapi.rwd.group',
    apiRoutes: {
        LOGIN: '/login',
        FORGOT_PASSWORD: '/forgottenPassword',
        RESET_LINKS: '/resetLinks',
        RESET_PASSWORD: '/resetPassword',
        LOGOUT: '/logout',
        USER: '/users/self',
        CHANGE_PASSWORD: '/changePassword',
        PRODUCTS: '/products',
        IMAGES: '/images',
        PRODUCT_CATEGORIES: '/productCategories',
        PRODUCT_SUB_CATEGORIES: '/productSubCategories',
        PRODUCT_SPECS: '/productSpecifications',
        PRODUCT_SPEC_ITEMS: '/productSpecificationItems',
        PRODUCT_OPTIONS: '/productOptions',
        PRODUCT_OPTION_ITEMS: '/productOptionItems',
        PRODUCT_OPTION_ITEM_VALUES: '/productOptionItemValues',
        PRODUCT_REVIEWS: '/productReviews',
        RELATED_PRODUCTS: '/relatedProducts',
        DELIVERY_COSTS: '/deliveryCosts',
        CUSTOMER_ACCOUNTS: '/customerAccounts',
        SHOP_ORDERS: '/shopOrders',
        ATTACHMENTS: '/attachments'
    },
    storageKeys: {
        USER: 'RWD_SHOP_CMS_USER',
        PREFS: 'RWD_SHOP_CMS_PREFS'
    },
    userRoles: {
        USER: 'ROLE_USER',
        ADMIN: 'ROLE_ADMIN',
        SUPER_ADMIN: 'ROLE_SUPER_ADMIN'
    }
};
