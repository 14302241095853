"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerAccountStore = exports.DeliveryTypes = void 0;
var Store_1 = require("./Store");
var DeliveryTypes;
(function (DeliveryTypes) {
    DeliveryTypes["INTERNATIONAL"] = "International";
})(DeliveryTypes = exports.DeliveryTypes || (exports.DeliveryTypes = {}));
var CustomerAccountStore = /** @class */ (function (_super) {
    __extends(CustomerAccountStore, _super);
    function CustomerAccountStore(data) {
        var _this = _super.call(this, data) || this;
        _this.setData = function (data) {
            _this.data = data;
        };
        return _this;
    }
    return CustomerAccountStore;
}(Store_1.Store));
exports.CustomerAccountStore = CustomerAccountStore;
var customerAccountStore = new CustomerAccountStore([]);
exports.default = customerAccountStore;
