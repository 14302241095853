"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.dummyOptions = exports.ProductOptionsStore = void 0;
var Store_1 = require("./Store");
var ProductOptionsStore = /** @class */ (function (_super) {
    __extends(ProductOptionsStore, _super);
    function ProductOptionsStore(data) {
        var _this = _super.call(this, data) || this;
        _this.setData = function (data) {
            _this.data = data;
        };
        return _this;
    }
    return ProductOptionsStore;
}(Store_1.Store));
exports.ProductOptionsStore = ProductOptionsStore;
exports.dummyOptions = [
    {
        id: 1,
        name: 'Size',
        productOptionItems: [
            {
                id: 1,
                name: 'Small'
            },
            {
                id: 2,
                name: 'Medium'
            },
            {
                id: 3,
                name: 'Large'
            }
        ]
    }
];
var productOptionsStore = new ProductOptionsStore([]);
exports.default = productOptionsStore;
