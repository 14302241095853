"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var FileInput = function (_a) {
    var name = _a.name, label = _a.label, optional = _a.optional, files = _a.files, multiple = _a.multiple, accept = _a.accept, onFileSelect = _a.onFileSelect, onFileError = _a.onFileError;
    var fileInput = react_1.useRef(null);
    var handleFileInput = function (e) {
        var files = e.currentTarget.files;
        for (var i = 0; i < files.length; i++) {
            if (files[i].size / 1024 / 1024 > 7168) {
                onFileError("Files cannot exceed 7MB in size");
                return;
            }
        }
        onFileSelect(name, files);
    };
    var getFileNames = function () {
        var array = [];
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            array.push(react_1.default.createElement("div", { key: "fi_" + i + "_" + file.name.toLowerCase().replace(/ /g, "_"), className: "file-input__files__file" },
                react_1.default.createElement("span", { key: i, className: "file-name" },
                    i + 1,
                    ". ",
                    file.name)));
        }
        return array;
    };
    return (react_1.default.createElement("div", { className: "input file flex flex-row" },
        react_1.default.createElement("label", null,
            label || _.startCase(name),
            " ",
            optional && react_1.default.createElement("small", null, "(Optional)")),
        react_1.default.createElement("div", { className: "file-input flex flex-row" },
            react_1.default.createElement("input", { type: "file", name: name, onChange: handleFileInput, accept: accept || "image/*,.pdf,.doc,.docx,.xls,.xlsx", multiple: multiple || true, ref: fileInput }),
            react_1.default.createElement("button", { type: "button", className: "button", onClick: function (e) { return fileInput.current && fileInput.current.click(); } }, "Select File/s"),
            react_1.default.createElement("div", { className: "file-input__files" }, files && files.length > 0 ?
                getFileNames()
                : react_1.default.createElement("span", { className: "empty" }, "No Files Selected")))));
};
exports.default = FileInput;
