"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrdersStore = exports.OrderStatus = void 0;
var Store_1 = require("./Store");
var OrderStatus;
(function (OrderStatus) {
    OrderStatus["PRE_PAYMENT"] = "Pre-Payment";
    OrderStatus["AWAITING_PAYMENT"] = "Awaiting Payment";
    OrderStatus["PAID"] = "Paid";
    OrderStatus["IN_PRODUCTION"] = "In Production";
    OrderStatus["DISPATCHED"] = "Dispatched";
    OrderStatus["ARRIVED"] = "Arrived";
    OrderStatus["CANCELLED"] = "Cancelled";
    OrderStatus["RETURNED"] = "Returned";
    OrderStatus["REFUNDED"] = "Refunded";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var OrdersStore = /** @class */ (function (_super) {
    __extends(OrdersStore, _super);
    function OrdersStore(data) {
        var _this = _super.call(this, data) || this;
        _this.setData = function (data) {
            _this.data = data;
        };
        return _this;
    }
    return OrdersStore;
}(Store_1.Store));
exports.OrdersStore = OrdersStore;
var ordersStore = new OrdersStore([]);
exports.default = ordersStore;
