"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationServiceProvider = exports.NotificationServiceContext = void 0;
var react_1 = __importStar(require("react"));
var Notification_1 = __importStar(require("components/Notification"));
var use_stores_1 = require("../hooks/use-stores");
var mobx_react_1 = require("mobx-react");
exports.NotificationServiceContext = react_1.default.createContext(null);
exports.NotificationServiceProvider = mobx_react_1.observer(function (_a) {
    var children = _a.children;
    var _b = use_stores_1.useStores(), apiStore = _b.apiStore, userStore = _b.userStore;
    var _c = react_1.default.useState(null), notificationState = _c[0], setNotificationState = _c[1];
    var notificationTimer = 1000;
    var notificationPosition = Notification_1.NotificationPositions.BOTTOM;
    if (userStore.data && userStore.data.settings) {
        notificationTimer = userStore.data.settings.generalSettings.notificationTimer;
        notificationPosition = userStore.data.settings.generalSettings.notificationPosition;
    }
    var _d = react_1.default.useState(false), visible = _d[0], setVisible = _d[1];
    var timerRef = react_1.useRef(null);
    var subTimerRef = react_1.useRef(null);
    var openNotification = function (options) {
        clearTimeout(timerRef.current);
        clearTimeout(subTimerRef.current);
        setNotificationState(options);
        setVisible(true);
        timerRef.current = setTimeout(function () {
            setVisible(false);
            if (apiStore.data.error !== null) {
                apiStore.editData({ error: null });
            }
            subTimerRef.current = setTimeout(function () {
                setNotificationState(null);
            }, 500);
        }, options.timeout || notificationTimer);
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(exports.NotificationServiceContext.Provider, { value: openNotification, children: children }),
        react_1.default.createElement(Notification_1.default, __assign({ visible: visible, position: notificationPosition }, notificationState))));
});
