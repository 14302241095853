"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var ProfileBasic = function (_a) {
    var data = _a.data, onChange = _a.onChange;
    var keyTimerRef = react_1.useRef(null);
    var changeHandler = function (e) {
        onChange(e.currentTarget.name, e.currentTarget.value);
    };
    var keyUpHandler = function (e) {
        clearTimeout(keyTimerRef.current);
        var name = e.currentTarget.name;
        var value = e.currentTarget.value;
        keyTimerRef.current = setTimeout(function () {
            onChange(name, value);
        }, 500);
    };
    return (react_1.default.createElement("div", { className: 'settings-section settings-section-general' },
        react_1.default.createElement("span", { className: 'settings-section__title' }, "Personal Information"),
        react_1.default.createElement("div", { className: 'settings-section__rows' },
            react_1.default.createElement("div", { className: 'settings-section__row flex flex-row flex-space-between' },
                react_1.default.createElement("label", null, "First Name"),
                react_1.default.createElement("input", { type: 'text', name: 'firstName', placeholder: 'First Name', defaultValue: data.firstName, onKeyUp: keyUpHandler })),
            react_1.default.createElement("div", { className: 'settings-section__row flex flex-row flex-space-between' },
                react_1.default.createElement("label", null, "Last Name"),
                react_1.default.createElement("input", { type: 'text', name: 'lastName', placeholder: 'Last Name', defaultValue: data.lastName, onKeyUp: keyUpHandler })),
            react_1.default.createElement("div", { className: 'settings-section__row flex flex-row flex-space-between' },
                react_1.default.createElement("label", null, "Email Address"),
                react_1.default.createElement("input", { type: 'email', name: 'emailAddress', placeholder: 'Email Address', defaultValue: data.emailAddress, onKeyUp: keyUpHandler })))));
};
exports.default = ProfileBasic;
