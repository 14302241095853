"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Store = void 0;
var mobx_1 = require("mobx");
var Store = /** @class */ (function () {
    function Store(data) {
        var _this = this;
        this.unsetData = function () {
            _this.data = null;
        };
        this.addDataItem = function (item) {
            if (!_this.checkArray())
                throw Error("Data is not an array, cannot edit items");
            _this.data.push(item);
        };
        this.editDataItem = function (item) {
            if (!_this.checkArray())
                throw Error("Data is not an array, cannot edit items");
            _this.data = _this.data.map(function (_item) { return _item.id === item.id ? item : _item; });
        };
        this.removeDataItem = function (item) {
            if (!_this.checkArray())
                throw Error("Data is not an array, cannot remove item");
            _this.data = _this.data.filter(function (_item) { return _item.id !== item.id; });
        };
        this.editData = function (data) {
            if (!_this.checkObject())
                throw Error("Data is not an object, cannot edit data");
            _this.data = __assign(__assign({}, _this.data), data);
        };
        this.data = data;
    }
    Store.prototype.checkArray = function () {
        return Array.isArray(this.data);
    };
    Store.prototype.checkObject = function () {
        return this.data.constructor === ({}).constructor;
    };
    __decorate([
        mobx_1.observable
    ], Store.prototype, "data", void 0);
    __decorate([
        mobx_1.action
    ], Store.prototype, "setData", void 0);
    __decorate([
        mobx_1.action
    ], Store.prototype, "unsetData", void 0);
    __decorate([
        mobx_1.action
    ], Store.prototype, "addDataItem", void 0);
    __decorate([
        mobx_1.action
    ], Store.prototype, "editDataItem", void 0);
    __decorate([
        mobx_1.action
    ], Store.prototype, "removeDataItem", void 0);
    __decorate([
        mobx_1.action
    ], Store.prototype, "editData", void 0);
    return Store;
}());
exports.Store = Store;
